import React, { useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import classNames from 'classnames'

import {
  getDefaultTemplates,
  templateCreate as templateCreateApi,
} from 'api/templates'
import WaitForData from 'components/WaitForData'
import Icons from 'components/Icons'
import { useToast } from 'components/Toast'
import TEMPLATE_TYPES from 'constants/templates'
import * as QueryUtils from 'utils/query'

import { getTemplateInfoFromType } from '../../utils'
import { getAccountData } from 'utils/account'

export default function Template({ builder }) {
  const {
    popup,
    savePopup,
    setSaveHandler,
    setSaveNextHandler,
    setActiveScreen,
  } = builder

  const defaultTemplatesQuery = useQuery(
    'default-templates',
    getDefaultTemplates
  )
  const defaultTemplates = QueryUtils.data(defaultTemplatesQuery)?.templates

  return (
    <div className="flex flex-col w-full px-16 py-10 ">
      <div className="flex flex-col items-start">
        <span className="text-lg font-semibold">Select Template</span>
      </div>
      <div className="min-h-[20rem] w-full flex items-center justify-center">
        <WaitForData objects={[defaultTemplatesQuery]} isQueries={true}>
          {defaultTemplates && (
            <TemplateSelect
              defaultTemplates={defaultTemplates}
              builder={builder}
            />
          )}
        </WaitForData>
      </div>
    </div>
  )
}

function TemplateSelect({ defaultTemplates, builder }) {
  const {
    popup,
    savePopup,
    setSaveHandler,
    setSaveNextHandler,
    setActiveScreen,
  } = builder
  const [selectedTemplate, setSelectedTemplate] = useState(
    popup?.templateId ? getInitialSelectedTemplate() : null
  )
  const openToast = useToast()
  const accountData = getAccountData()

  function getInitialSelectedTemplate() {
    if (!popup.templateId) {
      return null
    }
    const selectedDefaultTemplateObj = defaultTemplates.find(
      (template) => template.shopTemplateId === popup.templateId
    )
    if (!selectedDefaultTemplateObj) {
      return null
    }

    const templateInfo = getTemplateInfoFromType(
      selectedDefaultTemplateObj.type
    )

    return {
      ...templateInfo,
      ...selectedDefaultTemplateObj,
    }
  }

  useEffect(() => {
    async function popupSave() {
      if (!selectedTemplate) {
        openToast({
          type: 'error',
          text: 'Please select a template',
        })
        return
      }
      let shopTemplateId = selectedTemplate.shopTemplateId
      if (!selectedTemplate.shopTemplateId) {
        // const {data} = await templateCreateApi(
        //   {
        //     name: selectedTemplate.name,
        //     type: TEMPLATE_TYPES.POPUP.value,
        //   },
        //   {
        //     shop: accountData.name,
        //   }
        // )
        // shopTemplateId = data.id
        shopTemplateId = 232302
        console.log('Create template')
      }

      return await savePopup({
        ...popup,
        template: shopTemplateId,
      })
    }
    setSaveHandler(() => popupSave)
    setSaveNextHandler(() => async () => {
      const savedPopup = await popupSave()
      if (savedPopup) {
        setActiveScreen('review')
      }
    })
  }, [selectedTemplate])
  return (
    <div className="grid items-stretch justify-around grid-cols-3 gap-20 overflow-x-hidden overflow-y-auto">
      {defaultTemplates &&
        defaultTemplates.map((template) => {
          const templateInfo = getTemplateInfoFromType(template.type)
          return (
            <TemplateItem
              key={template.id}
              template={{ ...templateInfo, ...template }}
              selectedTemplate={selectedTemplate}
              setSelectedTemplate={setSelectedTemplate}
            />
          )
        })}
    </div>
  )
}

function TemplateItem({ template, selectedTemplate, setSelectedTemplate }) {
  const isSelected = selectedTemplate && selectedTemplate.type == template.value
  return (
    <div
      className="flex flex-col overflow-hidden border border-gray-300 rounded-lg cursor-pointer group hover:border-primary focus:border-primary mt-7"
      onClick={() => {
        setSelectedTemplate(template)
      }}
    >
      <div className="flex flex-col items-stretch pb-6 pr-4 pt-7 pl-7 min-h-[7rem] justify-center">
        <div className="flex flex-row items-center justify-between">
          <span className="text-sm font-semibold">{template.label}</span>
          <div className="flex flex-row items-center">
            {/* <Button size="small" variant="text" className="mr-4">
              Preview
            </Button> */}
            <span
              className={classNames(
                'flex items-center justify-center w-5 h-5 border border-gray-300 rounded group-hover:bg-primary',
                {
                  'bg-primary': isSelected,
                }
              )}
            >
              <Icons.Tick className="w-3 text-white stroke-current" />
            </span>
          </div>
        </div>
        <span className="mt-2 text-xs text-gray-500">
          {template.description}
        </span>
      </div>
      <div className="relative flex flex-row items-center justify-center w-full h-full p-6 bg-gray-800">
        <img src={template.image} alt={template.label} />
        {/* <div className="absolute inset-0 z-10 flex items-center justify-center w-full opacity-80 hover:bg-gray-900">
          <div className="flex flex-row items-center text-white">
            <Icons.WidgetView className="w-8" />
            <span className="ml-3 text-base font-semibold">Preview</span>
          </div>
        </div> */}
      </div>
    </div>
  )
}
