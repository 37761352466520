import React from 'react'

import Box from 'components/Box'
import { trackEvent } from 'utils/analytics'

import ReviewBitLogo from './assets/review_bit.png'
import RushLogo from './assets/rush.png'

export default function RecommendedApps({ account }) {
  return (
    <Box>
      <div className="flex flex-col items-stretch">
        <span className="mb-3 text-sm font-semibold text-gray-800 uppercase">
          Pair us with the best apps
        </span>
        {recommendedApps.map((app) => {
          return (
            <a
              key={app.name}
              className="flex flex-row items-center w-full py-3 cursor-pointer hover:bg-gray-100"
              href={app.siteUrl}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                trackEvent({
                  category: 'Recommended Apps',
                  label: app.name,
                  action: 'Clicked',
                })
              }}
            >
              <div className="flex flex-row justify-center w-2/5 mr-3 2xl:w-1/4">
                <img
                  src={app.logoUrl}
                  alt={app.name}
                  className="object-contain w-20 h-16"
                />
              </div>
              <div className="flex flex-col w-3/5 2xl:w-3/4">
                <span className="mb-1 text-sm font-semibold lg:text-xs 2xl:text-sm">
                  {app.name}
                </span>
                <span className="text-xs lg:text-xxs 2xl:text-xs">
                  {app.description}
                </span>
              </div>
            </a>
          )
        })}
      </div>
    </Box>
  )
}

const recommendedApps = [
  {
    logoUrl: ReviewBitLogo,
    name: 'Reviewbit',
    description:
      'Reviewbit helps eCommerce brands provide post-purchase consumer engagements using social messaging platforms like WhatsApp.',
    siteUrl:
      'https://apps.shopify.com/whatsapp-reviews?st_source=autocomplete&utm_source=glood&utm_medium=partnership&utm_campaign=inapprecommendation&utm_id=backlink',
  },
  {
    logoUrl: RushLogo,
    name: 'Rush',
    description:
      'The all-in-one post-purchase solution that turns one-time buyers into enthusiastic, life-long paying customers.',
    siteUrl:
      'https://a.rush.app/glood?utm_source=personalized%20recommendations&utm_medium=dashboard&utm_campaign=recommended_apps',
  },
]
