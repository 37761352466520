import React from 'react'
import classNames from 'classnames'
import { Disclosure } from '@headlessui/react'

import AddWidgetModal from 'containers/Dashboard/AddWidgetModal'
import Box from 'components/Box'
import Button from 'components/Button'
import Tooltip from 'components/Tooltip'
import Icons from 'components/Icons'
import Badge from 'components/Badge'
import { useOpenModal } from 'components/Modal'
import { getPercentage } from 'utils/numbers'

import Details from './Details'
import StatusSelector from './Fields/StatusSelector'
import Options from './Fields/Options'

export default function ExperienceCard({
  experience,
  updateWidgetData,
  updateLocalExperiences,
  billing,
  account,
  totalWeight,
  pages,
  canUnpublish,
  openUpgradeAccount,
}) {
  const openModal = useOpenModal()
  return (
    <Box className="flex flex-col w-full mb-3" key={experience.id}>
      <Disclosure defaultOpen={experience.isDefault}>
        {({ open }) => (
          <>
            <Disclosure.Button
              className="cursor-pointer focus:outline-none"
              as="div"
            >
              <div
                className={classNames(
                  'flex flex-row items-center justify-between '
                )}
              >
                <div className="flex flex-row items-center justify-start">
                  <div className="flex flex-row items-center justify-start mr-8">
                    <Icons.ChevronDown
                      className={classNames('w-4 mr-2 text-gray-700')}
                      style={{
                        transition: 'all 0.25s',
                        transform: open ? 'rotate(180deg)' : '',
                      }}
                    />

                    <span className="text-xs leading-tight tracking-wide text-gray-700 uppercase">
                      {experience.name}
                    </span>
                  </div>

                  <Tooltip
                    position="top"
                    content={
                      <div className="flex flex-col">
                        {experience.status === 'published' ? (
                          <span className="mb-1">
                            Showing to{' '}
                            {getPercentage(experience.weight, totalWeight)}% of
                            the current visitors
                          </span>
                        ) : (
                          <span className="mb-1">
                            This experience is unpublished and will not be shown
                            to any visitors.
                          </span>
                        )}
                      </div>
                    }
                    helpIconVariant="dark"
                  >
                    <span className="flex mr-8 text-xs font-semibold leading-tight">
                      Weightage : {experience.weight}
                    </span>
                  </Tooltip>

                  <div className="-mb-3">
                    <StatusSelector
                      canUnpublish={canUnpublish}
                      experience={experience}
                      totalWeight={totalWeight}
                      shop={account.data.name}
                      updateLocalExperiences={updateLocalExperiences}
                      openUpgradeAccount={openUpgradeAccount}
                    />
                  </div>
                </div>
                <div className="flex flex-row items-center">
                  <Button
                    className="ml-2 mr-3"
                    size="small"
                    variant="bordered"
                    onClick={(event) => {
                      event.stopPropagation()
                      openModal(
                        <AddWidgetModal
                          pages={pages}
                          billing={billing}
                          account={account}
                          experience={experience}
                          updateWidgetData={updateWidgetData}
                        />
                      )
                    }}
                  >
                    + Add New Widget
                  </Button>
                  <div className="-mb-3">
                    <Options
                      experience={experience}
                      totalWeight={totalWeight}
                      billing={billing}
                      account={account}
                      updateLocalExperiences={updateLocalExperiences}
                      openUpgradeAccount={openUpgradeAccount}
                    />
                  </div>
                </div>
              </div>
              <ExperienceInfo experience={experience} open={open} />
            </Disclosure.Button>
            <Disclosure.Panel>
              <Details
                updateWidgetData={updateWidgetData}
                experience={experience}
                account={account}
                pageOrder={(pages.data || []).reduce(
                  (order, page) => [...order, ...page.order],
                  []
                )}
              />
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </Box>
  )
}

function ExperienceInfo({ experience, open }) {
  return (
    <div
      className={classNames(
        'flex flex-row items-center justify-between text-xs mt-5',
        { 'border-b pb-2 mb-3': open }
      )}
    >
      <div className="flex flex-row items-center">
        <span className="flex mr-1 text-xs leading-tight">
          (Slug : {experience.slug},
        </span>
        <span className="flex mr-2 text-xs leading-tight">
          ID : {experience.id})
        </span>
      </div>
      <div className="flex flex-row">
        {experience.status === 'published' &&
          experience.startTime &&
          experience.startTime > new Date().getTime() && (
            <Badge color="yellow" className="uppercase" spacing="regular">
              Scheduled
            </Badge>
          )}
        {experience.isDefault && (
          <Badge color="neutral" className="ml-3 uppercase" spacing="regular">
            Default
          </Badge>
        )}
      </div>
    </div>
  )
}
