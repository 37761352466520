import React from 'react'
import classNames from 'classnames'
import { useForm } from 'react-hook-form'

import Box from 'components/Box'
import Button from 'components/Button'
import InfoBox from 'components/InfoBox'
import Form from 'components/Form'
import ProductDiscounts from '../ProductDiscounts'
import { openChat } from 'utils/external'
import { canAddProductDiscounts, isShopifyPlus } from 'utils/account'
import { useApi } from 'hooks/api'
import * as Layouts from 'constants/layouts'
import * as Widgets from 'constants/widgets'
import * as Pages from 'constants/pages'
import UpgradeAccountModal from 'components/UpgradeAccountModal'
import { useOpenModal } from 'components/Modal'
import { isFeatureEnabled } from 'utils/features'
import { PRODUCT_DISCOUNTS } from 'constants/features'

export default function Discounts({ account, widget, onSave, onDelete }) {
  const { name: shopName, shop, plan } = account.data
  const layoutSupported =
    widget.type === Widgets.BOUGHT_TOGETHER.value
      ? widget.layout !== 'amazon_bought_together'
      : true
  const shopifyPlusStore = isShopifyPlus(account.data)
  const productDiscountsSupported = shopifyPlusStore && layoutSupported
  const prodDiscountsEnabled = isFeatureEnabled(account.data, PRODUCT_DISCOUNTS)
  const openModal = useOpenModal()
  function openUpgradeAccount() {
    openModal(
      <UpgradeAccountModal
        account={account}
        featureToEnable={PRODUCT_DISCOUNTS}
      />
    )
  }

  async function onWidgetSave(newData) {
    await onSave({
      ...widget,
      discountConfig: { ...widget.discountConfig, ...newData },
    })
  }

  let child
  // Check if Feature is enabled for shop or not.
  if (plan.featuresEnabled.indexOf('discounts') === -1) {
    child = <EnableDiscountFeatureForShopCallout plan={plan} />
  } else if (shop.accessScopes.indexOf('write_price_rules') === -1) {
    child = <UpdatePermissionsCallout shop={shopName} />
  } else if (widget.pageType !== Pages.PRODUCT.value) {
    child = <BundleDiscountUnsupported mode="page" />
  } else if (widget.layout !== Layouts.AMAZON_BOUGHT_TOGETHER.value) {
    child = <BundleDiscountUnsupported mode="layout" />
  } else {
    child = <DiscountForm widget={widget} onWidgetSave={onWidgetSave} />
  }

  return (
    <div className="flex flex-col">
      {child}
      {
        <div className="flex flex-col items-end w-full">
          <div
            className={classNames('w-full', {
              'opacity-50 pointer-events-none': !productDiscountsSupported,
            })}
          >
            <ProductDiscounts
              account={account}
              widget={widget}
              onSave={onSave}
              onUpgrade={!prodDiscountsEnabled && openUpgradeAccount}
            />
          </div>
          {!productDiscountsSupported && (
            <span className="text-xs text-red-500">
              Product discounts are only available on layouts other than 'Amazon
              Like Bought Together' for Shopify Plus customers.
            </span>
          )}
        </div>
      }
    </div>
  )
}

function BundleDiscountUnsupported({ mode }) {
  return (
    <InfoBox
      type="warning"
      title={`${
        mode === 'layout' ? 'Layout' : 'Page'
      } does not support Bundle Discounts`}
      action={{
        content: 'Get In Touch',
        onAction: () => openChat(),
        external: false,
      }}
    >
      <p>
        Bundle discounts is only available for{' '}
        <strong>"Amazon Like Bought Together" layout</strong> on the{' '}
        <strong>Product Page</strong>.{' '}
        {mode === 'layout' &&
          'Please update the widget layout from "Design & Location" tab to enable discounts.'}
      </p>
    </InfoBox>
  )
}

function EnableDiscountFeatureForShopCallout({ plan }) {
  const isCharged = plan.isCharged
  const price = parseInt(plan.price)

  if (isCharged && price > 0) {
    //If charged ask to contact support.
    return (
      <InfoBox
        type="warning"
        title="Beta feature! Get in touch."
        action={{
          content: 'Get In Touch',
          onAction: () => openChat(),
          external: false,
        }}
      >
        <div>
          <p>
            This feature is being beta tested right now. Get in touch with us to
            get early access
          </p>
        </div>
      </InfoBox>
    )
  } else {
    // Else ask to upgrade.
    return (
      <InfoBox
        type="warning"
        title="Upgrade to unlock discount features!"
        action={{
          content: 'Upgrade Plan',
          link: '/plans/',
          external: false,
        }}
      >
        <div>
          <p>
            Once you upgrade, you can provide bundle discounts on the Frequently
            Bought Together Widget.
          </p>
        </div>
      </InfoBox>
    )
  }
}

function UpdatePermissionsCallout({ shop }) {
  const permission = useApi(`shopify/auth/update_permissions_link/`, {
    shop: shop,
  })

  function onGrantPermission(data) {
    const { updatePermissionsLink: link } = permission.data
    window.top.location.href = link
  }

  return (
    <InfoBox
      type="warning"
      title="Grant us permission to create bundle discounts!"
      action={{
        content: 'Grant Permission',
        onAction: onGrantPermission,
      }}
    >
      <div>
        <p>
          Once we have your permission, you can provide bundle discounts on the
          Frequently Bought Together widget.
        </p>
      </div>
    </InfoBox>
  )
}

function DiscountForm({ widget, onWidgetSave }) {
  const { register, handleSubmit, watch, errors } = useForm({
    defaultValues: {
      enabled: widget.discountConfig.enabled,
      type: widget.discountConfig.type,
      cartMessage: widget.discountConfig.cartMessage,
      widgetMessage: widget.discountConfig.widgetMessage,
      value: widget.discountConfig.value,
    },
  })
  const watchEnabled = watch('enabled')
  const watchType = watch('type')
  const isPercentage = watchType === 'PERCENTAGE'
  const valueHelpText = isPercentage
    ? '% discount applicable on total price of items in the bundle.'
    : 'Discount amount applicable across all items in the bundle.'
  const disclaimerPoints = [
    `A new discount with code "RK-BUNDLE-..." will be dynamically created on your Shopify Admin (discounts section) for each unique bundle added to the cart.`,
    `The discounted amount won't be displayed in the shopping cart page (this is a Shopify limitation). To overcome that, you may prefer to directly redirect to the checkout page.`,
    `While the discount is restricted to the products in the bundle, due to the Shopify Discount System characteristics, the discount will be applied to any combination of these products, even if they appear in a different number, as long as the mimimum number remains in the cart.`,
    <span>
      If you disable discounts, please note that all previous discount codes may
      still be valid. You can{' '}
      <span
        className="text-primary underline cursor-pointer"
        onClick={() => openChat()}
      >
        get in touch with us
      </span>{' '}
      and we will remove them for you.
    </span>,
  ]

  return (
    <Box className="mb-5">
      <div className="mb-5 text-sm font-semibold text-gray-800 uppercase">
        Bundle Discount Settings
      </div>
      <InfoBox
        type="warning"
        title="Important: Using bundle discounts has some implications"
      >
        <div className="mt-2">
          {disclaimerPoints.map((point, index) => {
            return (
              <li className="text-sm text-gray-800" key={index}>
                {point}
              </li>
            )
          })}
        </div>
      </InfoBox>
      <form
        className="flex flex-col items-stretch flex-1 w-full"
        onSubmit={handleSubmit(onWidgetSave)}
      >
        <Form.Field
          label="Enable Bundle Discounts"
          name="enabled"
          control="checkbox"
          ref={register()}
          spacing="loose"
          helpText="Check this option to enable Bundle Discounts for this widget."
        />
        {watchEnabled === true && (
          <React.Fragment>
            <div className="flex flex-row justify-between -mx-4">
              <div className="flex-1 px-4">
                <Form.Field
                  label="Discount Type"
                  name="type"
                  control="select"
                  options={[
                    { label: 'Amount', value: 'AMOUNT' },
                    { label: 'Percentage', value: 'PERCENTAGE' },
                  ]}
                  ref={register()}
                  default="AMOUNT"
                  helpText="How should the discount be calculated."
                />
              </div>
              <div className="flex-1 px-4">
                <Form.Field
                  label="Value"
                  name="value"
                  control="input"
                  className="flex-1 mr-4"
                  type="number"
                  min={1}
                  max={isPercentage ? 100 : 99999999}
                  ref={register()}
                  spacing="loose"
                  suffix={isPercentage ? '%' : null}
                  helpText={valueHelpText}
                />
              </div>
            </div>
            <div className="flex flex-row justify-between -mx-4">
              <div className="flex-1 px-4">
                <Form.Field
                  label="Discount Message Shown in Widget"
                  name="widgetMessage"
                  control="input"
                  ref={register()}
                  spacing="loose"
                  helpText="This message is shown below the widget heading. You can use any language."
                />
              </div>
              <div className="flex-1 px-4">
                <Form.Field
                  label="Message shown on Cart Page for applied discount"
                  name="cartMessage"
                  control="input"
                  ref={register()}
                  spacing="loose"
                  helpText="Due to a limitation in shopify, applied discount is visible only on the Checkout page. This message assures the buyer that their discount has been applied and will be visible on the Checkout page. You can use any language."
                />
              </div>
            </div>
          </React.Fragment>
        )}
        <div className="flex flex-row justify-end">
          <Button size="small">Save</Button>
        </div>
      </form>
    </Box>
  )
}
