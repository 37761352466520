import React, { useEffect } from 'react'
import { createApp } from '@shopify/app-bridge'
import { Redirect } from '@shopify/app-bridge/actions'

import { APP_BRIDGE_CONFIG } from 'constants/shopify'
import Loader from 'components/Loader'
import { updateURLSearch } from 'utils/router'

export default function ExitIFrame({}) {
  const app = createApp(APP_BRIDGE_CONFIG)
  const redirect = Redirect.create(app)
  useEffect(() => {
    redirect.dispatch(
      Redirect.Action.REMOTE,
      `${window.location.origin}${window.location.pathname}?${updateURLSearch({
        embedded: '0',
      })}`
    )
  }, [])

  return (
    <div className="flex items-center justify-center flex-1">
      <Loader message="Redirecting" />
    </div>
  )
}
