import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import classNames from 'classnames'

import { POPUP_RECOMMENDATION_TYPES_LIST } from 'containers/Popups/constants'
import Form from 'components/Form'
import Icons from 'components/Icons'
import Tooltip from 'components/Tooltip'
import { useToast } from 'components/Toast'
import { getNearestHalfHour } from 'utils/date'

export default function Details({ builder }) {
  const {
    popup,
    savePopup,
    setSaveHandler,
    setSaveNextHandler,
    setActiveScreen,
  } = builder

  const {
    register,
    handleSubmit,
    watch,
    errors,
    formState,
    getValues,
    control: formControl,
  } = useForm({
    mode: 'onChange',
    defaultValues: getDefaultValues(popup),
  })
  const formValues = watch()

  const [selectedRecommendationType, setSelectedRecommendationType] = useState(
    popup.recommendationType ? popup.recommendationType : null
  )
  const openToast = useToast()
  useEffect(() => {
    async function popupSave(formData) {
      if (!selectedRecommendationType) {
        openToast({
          type: 'error',
          text: 'Recommendation Type must be selected.',
        })
        return
      }
      var payload = Object.fromEntries(
        Object.entries(formData).filter(([_, v]) => v != null)
      )
      if (payload.endTime) {
        payload.endTime = Math.floor(payload.endTime.getTime() / 1000)
      }
      if (payload.startTime) {
        payload.startTime = Math.floor(payload.startTime.getTime() / 1000)
      }
      payload.recommendationType = selectedRecommendationType
      return savePopup({ ...popup, ...payload })
    }
    setSaveHandler(() => handleSubmit(popupSave))
    setSaveNextHandler(() => async () => {
      await handleSubmit(
        async function onSubmit(formData) {
          const savedPopup = await popupSave(formData)
          if (savedPopup) {
            setActiveScreen('trigger')
          }
        },
        function onError(errors, e) {
          setActiveScreen('details')
        }
      )()
    })
  }, [selectedRecommendationType])
  return (
    <div className="flex flex-col items-start w-full">
      <BasicDetails
        builder={builder}
        formProps={{
          register,
          errors,
          getValues,
          formControl,
          formValues,
        }}
      />
      <div className="w-full h-0.5 bg-gray-200"></div>
      <RecommendationType
        builder={builder}
        selectedRecommendationType={selectedRecommendationType}
        setSelectedRecommendationType={setSelectedRecommendationType}
      />
    </div>
  )
}

function getDefaultValues(popup) {
  return {
    name: popup.name,
    startTime: popup?.startTime ? new Date(popup.startTime * 1000) : null,
    endTime: popup?.endTime ? new Date(popup.endTime * 1000) : null,
  }
}

function BasicDetails({ builder, formProps }) {
  const { register, errors, getValues, formControl, formValues } = formProps
  return (
    <div className="flex flex-col w-full px-16 py-10">
      <Form.Field
        label="Name"
        name="name"
        control="input"
        ref={register({
          required: 'Popup name cannot be empty.',
        })}
        formError={errors.name}
      />
      <Form.FieldRow>
        <Form.Field
          label="Start date & time"
          name="startTime"
          control="date-time"
          autoFocus={false}
          date={true}
          time={true}
          formControl={formControl}
          min={getNearestHalfHour()}
          max={formValues.endTime ? formValues.endTime : undefined}
          formError={errors.startTime}
        />
        <Form.Field
          label="End date & time"
          name="endTime"
          control="date-time"
          autoFocus={false}
          date={true}
          time={true}
          formControl={formControl}
          rules={{
            validate: (value) => {
              if (getValues().startTime && !value) {
                return 'End Time must be specified'
              }
              return true
            },
          }}
          min={
            formValues.startTime ? formValues.startTime : getNearestHalfHour()
          }
          formError={errors.endTime}
        />
      </Form.FieldRow>
    </div>
  )
}

function RecommendationType({
  builder,
  setSelectedRecommendationType,
  selectedRecommendationType,
}) {
  const {
    accountData: {
      plan: { recommendationsEnabled },
    },
  } = builder

  return (
    <div className="flex flex-col w-full px-16 py-10">
      <div className="flex flex-col items-start">
        <span className="text-lg font-semibold">
          Select type of recommendation
        </span>
        {/* <span className="mt-2 text-base text-gray-500">
          Complete the following steps to get started
        </span> */}
      </div>
      <div className="grid items-stretch grid-cols-3 gap-5 mt-5 lg:grid-cols-4">
        {POPUP_RECOMMENDATION_TYPES_LIST.map((type) => {
          const isUnavailable =
            (typeof type.enabled !== 'undefined' && !type.enabled) ||
            !recommendationsEnabled.includes(type.value)
          return (
            <Tooltip
              content={
                type.tooltipContent
                  ? type.tooltipContent
                  : isUnavailable
                  ? 'This page type is not supported on your plan'
                  : null
              }
              triggerClassName="!flex"
              key={type.value}
            >
              <div
                className={classNames(
                  'flex flex-row items-start justify-between p-4 border border-gray-400 rounded shadow-sm w-full',
                  {
                    'opacity-50 cursor-not-allowed': isUnavailable,
                    'cursor-pointer hover:border-primary focus:border-primary-400 group':
                      !isUnavailable,
                  }
                )}
                onClick={() => {
                  if (!isUnavailable) {
                    setSelectedRecommendationType(type.value)
                  }
                }}
              >
                <div className="flex flex-col items-center justify-start h-full">
                  <div className="flex flex-row items-center justify-start w-full">
                    <span className="text-sm font-semibold">{type.label}</span>
                  </div>
                  <span className="mt-2 text-xs text-gray-600">
                    {type.description}
                  </span>
                </div>
                <div className="flex flex-col items-center justify-between w-1/5 ml-4">
                  <span
                    className={classNames(
                      'flex items-center justify-center w-5 h-5 border border-gray-300 rounded group-hover:border-primary-400 group-hover:border',
                      {
                        'bg-primary': selectedRecommendationType === type.value,
                      }
                    )}
                  >
                    <Icons.Tick className="w-3 text-white stroke-current" />
                  </span>
                </div>
              </div>
            </Tooltip>
          )
        })}
      </div>
    </div>
  )
}
