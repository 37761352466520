import React from 'react'
import { format } from 'date-fns'
import classNames from 'classnames'
import _uniqBy from 'lodash.uniqby'
import { useHistory } from 'react-router-dom'

import WaitForData from 'components/WaitForData'
import Text from 'components/Text'
import InfiniteScroll from 'components/InfiniteScroll'
import Table from 'components/Table'
import Button from 'components/Button'
import Loader from 'components/Loader'

import { Select } from 'components/Form/controls'
import DateRangePicker from 'components/DateRangePicker'
import WidgetMultiSelect from 'components/MultiSelects/Widgets'
import useAttribution from './hooks/useAttribution'
import { getCurrencyFormat, getStoreCurrency } from 'utils/account'
import { renderCurrency } from 'utils/currency'

export default function Attribution({ account, store }) {
  const {
    onDateRangeChange,
    onCurrencyChange,
    showWidgetId,
    attribution,
    pages,
    attributionState,
    setSelectedWidgetIds,
    handleExport,
  } = useAttribution({ account })

  const storeCurrency = getStoreCurrency(account.data)
  const showCurrencySwitcher = storeCurrency != 'USD'
  const isNativeCurrencyMode = attributionState.currency === 'native'
  const currencyFormat = getCurrencyFormat(account, store)
  const history = useHistory()
  var allWidgetIds = (pages.data || [])
    .reduce((widgets, page) => [...widgets, ...page.widgets], [])
    .map((widget) => widget.id)

  return (
    <div className="flex flex-col items-stretch flex-1">
      <Text.ScreenHeading>Attributed Orders</Text.ScreenHeading>
      <div className="flex flex-row justify-end mb-4">
        <div className="flex flex-row items-stretch justify-end">
          {showCurrencySwitcher && (
            <div className="h-full mr-3">
              <Select
                label=""
                name="currency"
                className="h-full px-2 py-2 text-base"
                options={[
                  {
                    label: `Native Currency (${storeCurrency})`,
                    value: 'native',
                  },
                  {
                    label: 'Dollars (USD)',
                    value: 'usd',
                  },
                ]}
                value={attributionState.currency}
                onChange={onCurrencyChange}
              />
            </div>
          )}
          <WaitForData objects={[pages]}>
            <WidgetMultiSelect
              className="mr-3"
              allWidgets={(pages.data || []).reduce(
                (widgets, page) => [...widgets, ...page.widgets],
                []
              )}
              selectedWidgetIds={attributionState.selectedWidgetIds}
              setSelectedWidgetIds={setSelectedWidgetIds}
            />
          </WaitForData>
          <DateRangePicker
            startDate={attributionState.startDate}
            endDate={attributionState.endDate}
            onRangeChange={onDateRangeChange}
            className="h-full mr-3"
          />
          <Button onClick={handleExport}>Export as CSV</Button>
        </div>
      </div>
      <WaitForData
        objects={[account, store, attribution]}
        loadingMessage="Fetching attribution data"
        errorMessage="Trouble fetching attribution data"
      >
        {() => {
          return (
            <InfiniteScroll
              onLoadMore={() => {
                attribution.fetchMore((existingData, newData) => {
                  return {
                    ...existingData,
                    ...newData,
                    orders: _uniqBy(
                      [...existingData.orders, ...(newData.orders || [])],
                      (p) => p.id
                    ),
                  }
                })
              }}
            >
              <div className="flex flex-col items-center">
                <div className="w-full">
                  <Table
                    className="w-full"
                    headings={[
                      'Order Id',
                      'Date',
                      'Attributed Items w/ Revenue',
                      'Total Order Value',
                      ...(showWidgetId ? ['Widget ID'] : []),
                    ]}
                    rowItems={attribution.data.orders}
                    renderRow={(item, index, rowClassName) => {
                      const widgetIds = item.attributedItems
                        .map((item) => item.widgetId)
                        .join(', ')
                      return (
                        <tr key={index} className={rowClassName}>
                          <td className="w-48 px-4 py-2 text-left border">
                            {item.name}
                          </td>
                          <td className="w-48 px-4 py-2 text-left border">
                            {format(
                              new Date(item.processedAt * 1000),
                              'd MMMM, yyyy'
                            )}
                          </td>
                          <td className="p-4 text-left border">
                            <ul
                              className={classNames(
                                'flex flex-col items-stretch',
                                {
                                  'pl-3 list-disc':
                                    item.attributedItems.length > 1,
                                }
                              )}
                            >
                              {item.attributedItems.map((i, index) => (
                                <li
                                  className={classNames('', {
                                    'mb-1 pb-1 border-b border-gray-400':
                                      index < item.attributedItems.length - 1,
                                  })}
                                  key={i.orderItemId}
                                >
                                  {i.product.title} ({i.product.variant.title})
                                  {i.product.variant.sku && (
                                    <span className="ml-1 text-xs text-gray-800">
                                      (SKU: {i.product.variant.sku})
                                    </span>
                                  )}{' '}
                                  -{' '}
                                  {isNativeCurrencyMode ? (
                                    <span
                                      className="font-semibold"
                                      dangerouslySetInnerHTML={{
                                        __html: renderCurrency(
                                          i.attributedRevenueNative,
                                          currencyFormat
                                        ),
                                      }}
                                    ></span>
                                  ) : (
                                    <span className="font-semibold">
                                      ${i.attributedRevenue}
                                    </span>
                                  )}
                                </li>
                              ))}
                            </ul>
                          </td>
                          <td className="w-32 p-4 text-left border">
                            {isNativeCurrencyMode ? (
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: renderCurrency(
                                    item.totalPriceNative,
                                    currencyFormat
                                  ),
                                }}
                              ></span>
                            ) : (
                              `$${item.totalPrice}`
                            )}
                          </td>
                          {showWidgetId ? (
                            <td
                              className={classNames(
                                'p-4 border text-left w-32',
                                {
                                  'text-black': item.widgetId,
                                  'text-gray-700 text-xs': !item.widgetId,
                                }
                              )}
                            >
                              {item.attributedItems.map(
                                (attribItem, attribIndex) => {
                                  const widgetAvailable = allWidgetIds.includes(
                                    attribItem.widgetId
                                  )
                                  return (
                                    <span
                                      key={`${attribIndex}-${attribItem.product.id}`}
                                      className={classNames(
                                        'text-center block ',
                                        {
                                          'mb-1 pb-1 border-b border-gray-400':
                                            attribIndex <
                                            item.attributedItems.length - 1,
                                          'hover:underline cursor-pointer':
                                            widgetAvailable,
                                        }
                                      )}
                                      onClick={() => {
                                        if (widgetAvailable) {
                                          history.push(
                                            `/widgets/${attribItem.widgetId}?shop=${account.data.name}`
                                          )
                                        }
                                      }}
                                    >
                                      {attribItem.widgetId}
                                    </span>
                                  )
                                }
                              )}
                            </td>
                          ) : null}
                        </tr>
                      )
                    }}
                  />
                </div>
                {attribution.loadingMore && <Loader className="mt-3" />}
              </div>
            </InfiniteScroll>
          )
        }}
      </WaitForData>
    </div>
  )
}
