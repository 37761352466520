const HOME = {
  value: 'home',
  label: 'Homepage',
  description:
    'Add recommendations on homepage to showcase personalized and top products. Prevent dropoffs and give a personalized experience.',
}

const PRODUCT = {
  value: 'product_page',
  label: 'Product Page',
  description:
    'Show relevant recommendations on Product Page. Show related products, up-sells, cross sells, etc.',
}

const COLLECTION = {
  value: 'collection',
  label: 'Collection Page',
  description: 'Show personalized recommendations on all collection pages.',
}

const CART = {
  value: 'cart',
  label: 'Cart Page',
  description:
    "Up-sell products on Cart page. Show recommendations based on a user's cart.",
}

const ORDER_CONFIRM = {
  value: 'order_confirm',
  label: 'Order Confirmation Page',
  description:
    'Add recommendations on Thank You page to convert one time buyers into repeat customers.',
}

const OTHER = {
  value: 'other',
  label: 'Custom Pages',
  description:
    'Add recommendations on any custom page of your store. Increase conversions by directing them to your store.',
}

const BLOG = {
  value: 'blog',
  label: 'Blogs & Articles',
  description:
    'Add relevant recommendations and Blog & Articles to attract customers to your store.',
}

const AJAX_CART = {
  value: 'ajax_cart',
  label: 'AJAX Cart',
  description:
    'Add recommendations on AJAX Cart, to increase AoV. This works only on themes that support AJAX Cart.',
}

const NOT_FOUND = {
  value: '404',
  label: '404 Not Found',
  description:
    'Add recommendations on 404 Not Found page to keep users on the store website.',
}

const CHECKOUT = {
  value: 'checkout',
  label: 'Checkout Page',
  description: 'Show personalized recommendations on the checkout page.',
  shopifyPlusOnly: true,
}

const RETURNS = {
  value: 'returns',
  label: 'Returns Page',
  description:
    'Show Personalized Recommendations on the returns pages. Needs Return Prime integration.',
}

export {
  HOME,
  PRODUCT,
  COLLECTION,
  CART,
  ORDER_CONFIRM,
  OTHER,
  BLOG,
  AJAX_CART,
  NOT_FOUND,
  CHECKOUT,
  RETURNS,
}
