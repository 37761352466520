import './index.css'
import 'react-widgets/dist/css/react-widgets.css'
import './base.css'

import './polyfills'

import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/browser'
import { BrowserRouter as Router } from 'react-router-dom'
import { ReactQueryDevtools } from 'react-query/devtools'
import { QueryClientProvider } from 'react-query'

import AppRoot from 'containers/App'
import { ModalProvider, ModalContent } from 'components/Modal'
import { ToastProvider, ToastContent } from 'components/Toast'
import { queryClient } from 'utils/query'

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://e605a790e0ff49d1b4941106f137108d@o302130.ingest.sentry.io/5759480',
  })
}

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { error: null }
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error })
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key])
      })
      Sentry.captureException(error)
    })
  }

  render() {
    return this.props.children
  }
}

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Router>
        <ModalProvider>
          <ToastProvider>
            <ErrorBoundary>
              <AppRoot />
              <ModalContent />
              <ToastContent />
            </ErrorBoundary>
          </ToastProvider>
        </ModalProvider>
      </Router>
      {process.env.NODE_ENV === 'development' && (
        <ReactQueryDevtools initialIsOpen />
      )}
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
