import { ReactComponent as ICON_CLOSE } from './close.svg'
import { ReactComponent as ICON_LOCK } from './lock.svg'
import { ReactComponent as ICON_INFLUENCER } from './influencer.svg'
import { ReactComponent as ICON_EMAIL } from './email.svg'
import { ReactComponent as ICON_TEMPLATES } from './templates.svg'
import { ReactComponent as ICON_FAST_LOAD } from './fast_loading.svg'
import { ReactComponent as ICON_FAILURE } from './failure.svg'
import { ReactComponent as ICON_DONE } from './done.svg'
import { ReactComponent as ICON_STAR } from './star.svg'
import { ReactComponent as ICON_CHEVRON_DOWN } from './chevron_down.svg'
import { ReactComponent as ICON_CHEVRON_RIGHT } from './chevron_right.svg'
import { ReactComponent as ICON_FILTER } from './filter.svg'
import { ReactComponent as ICON_SEGMENTS } from './segments.svg'
import { ReactComponent as ICON_WIDGET_VIEW } from './widget_view.svg'
import { ReactComponent as ICON_WIDGET_SERVE } from './widget_serve.svg'
import { ReactComponent as ICON_PAGE_VIEW } from './page_view.svg'
import { ReactComponent as ICON_CLICK } from './click.svg'
import { ReactComponent as ICON_CART } from './cart.svg'
import { ReactComponent as ICON_KEY_MAJOR } from './key_major.svg'
import { ReactComponent as ICON_SEARCH } from './search.svg'
import { ReactComponent as ICON_CIRCLE_TICK } from './circle_tick.svg'
import { ReactComponent as ICON_COG } from './cog.svg'
import { ReactComponent as ICON_DESKTOP } from './desktop.svg'
import { ReactComponent as ICON_MOBILE } from './mobile.svg'
import { ReactComponent as ICON_CODE } from './code.svg'
import { ReactComponent as ICON_GLOOD } from './glood.svg'
import { ReactComponent as ICON_DOLLAR } from './dollar.svg'
import { ReactComponent as ICON_CIRCLE_TICK_THIN } from './circle_tick_thin.svg'
import { ReactComponent as ICON_YOUTUBE } from './youtube.svg'
import { ReactComponent as ICON_ADD_PRODUCT } from './add_product.svg'
import { ReactComponent as ICON_CROSS } from './cross.svg'
import { ReactComponent as ICON_DELETE } from './delete.svg'
import { ReactComponent as ICON_PRE_CHECKOUT } from './pre_checkout.svg'
import { ReactComponent as ICON_POST_CHECKOUT } from './post_checkout.svg'
import { ReactComponent as ICON_UPLOAD } from './upload.svg'
import { ReactComponent as ICON_DRAG } from './drag.svg'
import { ReactComponent as ICON_TRIANGLE_UP } from './triangle_up.svg'
import { ReactComponent as ICON_TICK } from './tick.svg'
import { ReactComponent as ICON_FUNNEL } from './funnel.svg'
import { ReactComponent as ICON_BUNDLE } from './bundle.svg'
import { ReactComponent as ICON_POPUP } from './popup.svg'
// import { ReactComponent as ICON_EXTERNAL } from './external.svg'

const Icons = {}

Icons.Close = ICON_CLOSE
Icons.Lock = ICON_LOCK
Icons.Influencer = ICON_INFLUENCER
Icons.Email = ICON_EMAIL
Icons.Templates = ICON_TEMPLATES
Icons.FastLoader = ICON_FAST_LOAD
Icons.Failure = ICON_FAILURE
Icons.Done = ICON_DONE
Icons.Star = ICON_STAR
Icons.ChevronDown = ICON_CHEVRON_DOWN
Icons.ChevronRight = ICON_CHEVRON_RIGHT
Icons.Filter = ICON_FILTER
Icons.Segments = ICON_SEGMENTS
Icons.WidgetView = ICON_WIDGET_VIEW
Icons.WidgetServe = ICON_WIDGET_SERVE
Icons.PageView = ICON_PAGE_VIEW
Icons.Click = ICON_CLICK
Icons.Cart = ICON_CART
Icons.KeyMajor = ICON_KEY_MAJOR
Icons.Search = ICON_SEARCH
Icons.CircleTick = ICON_CIRCLE_TICK
Icons.Settings = ICON_COG
Icons.Desktop = ICON_DESKTOP
Icons.Mobile = ICON_MOBILE
Icons.Code = ICON_CODE
Icons.Glood = ICON_GLOOD
Icons.Dollar = ICON_DOLLAR
Icons.CircleTickThin = ICON_CIRCLE_TICK_THIN
Icons.Youtube = ICON_YOUTUBE
Icons.AddProduct = ICON_ADD_PRODUCT
Icons.Cross = ICON_CROSS
Icons.Delete = ICON_DELETE
Icons.PreCheckout = ICON_PRE_CHECKOUT
Icons.PostCheckout = ICON_POST_CHECKOUT
Icons.Upload = ICON_UPLOAD
Icons.Drag = ICON_DRAG
Icons.TriangleUp = ICON_TRIANGLE_UP
Icons.Tick = ICON_TICK
Icons.Funnel = ICON_FUNNEL
Icons.Bundle = ICON_BUNDLE
Icons.Popup = ICON_POPUP
// Icons.External = ICON_EXTERNAL

export default Icons
