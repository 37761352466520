import React, { useEffect, useState } from 'react'

import { shopifyInitiate } from 'api/shopify'
import Loader from 'components/Loader'
import Button from 'components/Button'
import Icons from 'components/Icons'
import { useQueryParams } from 'hooks/router'
import { openChat } from 'utils/external'

import ExitIFrame from '../ExitIFrame'

export default function Install() {
  const queryParams = useQueryParams()
  if (queryParams.embedded === '1') {
    return <ExitIFrame />
  } else {
    return <LoadInitiate queryParams={queryParams} />
  }
}

function LoadInitiate({ queryParams }) {
  const [error, setError] = useState(false)
  useEffect(() => {
    async function initiate() {
      try {
        const {
          data: { redirectUrl },
        } = await shopifyInitiate(queryParams)
        window.location.href = redirectUrl
      } catch (err) {
        setError(true)
      }
    }
    initiate()
  }, [])

  if (error) {
    return (
      <div className="flex items-center justify-center flex-1 bg-gray-200">
        <div className="flex flex-col items-center justify-center">
          <Icons.Glood className="w-32 text-primary" />
          <span className="mt-8 text-lg">
            There was an error processing your request
          </span>
          <Button onClick={openChat} className="mt-4" size="small">
            Contact Support
          </Button>
        </div>
      </div>
    )
  }
  return (
    <div className="flex items-center justify-center flex-1">
      <Loader message="Fetching details" />
    </div>
  )
}
