import api from 'api'
import { useState, useEffect } from 'react'
import { report } from 'utils/reporting'

export function useApi(url, initialParams = {}) {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [updating, setUpdating] = useState(false)
  const [loadingMore, setLoadingMore] = useState(false)
  const [currentPage, setCurrentPage] = useState(initialParams.page || 1)
  const [requestParams, setRequestParams] = useState(initialParams || {})
  const { shop } = requestParams

  async function get(params = {}, options = {}) {
    const newRequestParams = { ...requestParams, ...params }
    setRequestParams(newRequestParams)

    const cachedEntryKey = cacheKey(url, newRequestParams)

    setError(false)

    if (apiCache[cachedEntryKey]) {
      setData(apiCache[cachedEntryKey])
    } else {
      setLoading(true)
    }

    try {
      const { data } = await fetchData(url, newRequestParams)
      setData(data)
      setCurrentPage(1)
    } catch (err) {
      setError(true)
    }

    setLoading(false)
  }

  async function update(
    payload,
    {
      optimistic = false,
      local = false,
      cacheKey = null,
      method = 'post',
      onSuccess,
      onFailure,
    } = {}
  ) {
    const existingData = data
    const newData = payload

    if (optimistic || local) {
      setData(newData)
    }

    if (cacheKey) {
      apiCache[cacheKey] = newData
    }

    if (local) {
      return
    }

    setUpdating(true)

    try {
      const { data } = await api[method](url, payload, {
        params: { ...requestParams, shop },
      })
      setData(data)
      onSuccess && onSuccess()
    } catch (err) {
      onFailure && onFailure()
      if (optimistic) {
        setData(existingData)
      }
    }

    setUpdating(false)
  }

  async function destroy(pk) {
    setUpdating(true)
    let result
    try {
      if (pk) {
        result = await api['delete'](`${url}${pk}/`, {
          params: { ...requestParams, shop },
        })
      } else {
        result = await api['delete'](url, {
          params: { ...requestParams, shop },
        })
      }
      setData(result.data)
    } catch (err) {
      report(err)
    }
    setUpdating(false)
  }

  async function fetchMore(mergeResults) {
    if (data && data.totalPages === currentPage) {
      return
    }

    setLoadingMore(true)
    const fetchMoreParams = {
      ...requestParams,
      page: currentPage + 1,
    }

    try {
      const { data: newData } = await fetchData(url, fetchMoreParams)
      const dataUpdate = mergeResults(data, newData)

      setData(dataUpdate)
      setCurrentPage(currentPage + 1)
    } catch (err) {
      setError(true)
    }

    setLoadingMore(false)
  }

  useEffect(() => {
    if (shop) {
      get()
    }
  }, [shop])

  return {
    get,
    fetchMore,
    data,
    loading,
    loadingMore,
    error,
    update,
    destroy,
    updating,
  }
}

const apiCache = {}

export function cacheKey(url, params = {}) {
  const queryString = Object.keys(params)
    .sort()
    .map((k) => `${k}=${params[k]}`)
    .join('&')

  return `api:${url}${queryString}`
}

async function fetchData(url, params) {
  const fetchKey = cacheKey(url, params)

  let result
  try {
    const data = await api.get(url, { params })
    if (data) {
      apiCache[fetchKey] = data.data
      // NOTE(DHRUV): Enable this and line:91 block if offline mode is needed for dev
      // localStorage.setItem(fetchKey, JSON.stringify(data.data))
      result = data
    }
  } catch (err) {}

  // if (!result) {
  //   result = JSON.parse(localStorage.getItem(fetchKey))
  // }
  return result
}
