import React, { useEffect, useState } from 'react'
import sub from 'date-fns/sub'
import differenceInDays from 'date-fns/differenceInDays'

import { parseStateDate } from 'utils/date'
import { isRkSupport } from 'utils/account'
import { useApi } from 'hooks/api'
import useAppState from 'hooks/appState'
import { useToast } from 'components/Toast'
import { BACKEND_DOMAIN } from 'constants/support'
import { _omit } from 'utils/lodash'

export default function useAttribution({ account }) {
  const pages = useApi('pages/', { shop: account.data.name })
  const openToast = useToast()

  const showWidgetId = isRkSupport(account.data)
  const { state: appState, updateState: updateAppState } = useAppState()
  var startDate = parseStateDate(
    appState.attribStartDate,
    sub(new Date(), { days: 7 })
  )
  var endDate = parseStateDate(appState.attribEndDate, new Date())
  const [state, setState] = useState({
    startDate,
    endDate,
    selectedWidgetIds: [],
    currency: appState.currencyMode ? appState.currencyMode : 'usd',
    params: {
      shop: account.data.name,
      ...(startDate ? { date__gte: parseInt(startDate.getTime() / 1000) } : {}),
      ...(endDate ? { date__lte: parseInt(endDate.getTime() / 1000) } : {}),
    },
  })
  const attribution = useApi('attribution/', state.params)

  useEffect(() => {
    attribution.get(state.params)
  }, [state.params])

  function onCurrencyChange(event) {
    const currency = event.target.value

    if (currency === 'native' && state.startDate < new Date(2022, 0, 1)) {
      openToast({
        type: 'error',
        text: 'Native Currency not available before JAN 1 2022',
      })
      return
    }
    setState({
      ...state,
      currency,
    })
    updateAppState({ currencyMode: currency })
  }

  function onDateRangeChange(date) {
    if (!date) {
      return
    }

    const [startDate, endDate] = date
    if (!startDate || !endDate) {
      return
    }

    if (state.currency === 'native' && startDate < new Date(2022, 0, 1)) {
      openToast({
        type: 'error',
        text: 'Native Currency not available before JAN 1 2022',
      })
      return
    }

    if (differenceInDays(endDate, startDate) > 30) {
      openToast({
        type: 'error',
        text: 'Cannot select range more than 30 days',
      })
      return
    }

    updateAppState({ attribStartDate: date[0], attribEndDate: date[1] })
    setState({
      ...state,
      startDate,
      endDate,
      params: {
        ...state.params,
        date__gte: parseInt(startDate.getTime() / 1000),
        date__lte: parseInt(endDate.getTime() / 1000),
      },
    })
  }

  function handleExport() {
    var link = document.createElement('a')
    link.setAttribute('download', 'GloodAI_attributed_orders.csv')
    link.href = `${BACKEND_DOMAIN}v1/attribution/download?${new URLSearchParams(
      state.params
    ).toString()}`
    document.body.appendChild(link)
    link.click()
    link.remove()
  }

  function changeSelectedWidgetIds(widgetIds) {
    if (widgetIds.length != 0) {
      setState({
        ...state,
        selectedWidgetIds: widgetIds,
        params: {
          ...state.params,

          widgets: widgetIds.join(','),
        },
      })
      return
    }
    setState({
      ...state,
      selectedWidgetIds: widgetIds,
      params: {
        ...state.params,

        widgets: undefined,
      },
    })
  }

  return {
    onDateRangeChange,
    onCurrencyChange,
    showWidgetId,
    attribution,
    pages,
    updateAppState,
    attributionState: state,
    setAttributionState: setState,
    handleExport,
    setSelectedWidgetIds: changeSelectedWidgetIds,
  }
}
