import React from 'react'
import numeral from 'numeral'
import classNames from 'classnames'

import Box from 'components/Box'
import { renderCurrency } from 'utils/currency'

import styles from './styles.module.css'
import Tooltip from 'components/Tooltip'

export default function StatCard({
  className,
  title,
  description,
  currency,
  type,
  total,
  value,
  numberFormat,
  currencySymbol,
  storeCurrency,
}) {
  const statValue = numeral(value).format(numberFormat)
  return (
    <div className={className} key={title}>
      <Box className="h-full">
        <div className="flex flex-col items-stretch">
          <span className="text-sm font-semibold text-gray-800 uppercase">
            {title}
          </span>
          <div className="mt-1 text-3xl font-semibold text-gray-800 notranslate">
            <span>
              {type == 'currency' ? (
                <Tooltip
                  content={
                    <span>
                      {currencySymbol
                        ? `${currencySymbol} ${value}`
                        : `${value} ${storeCurrency}`}
                    </span>
                  }
                >
                  <span className="flex flex-row items-center justify-start">
                    {currencySymbol ? (
                      <React.Fragment>
                        <span className="mr-1">{currencySymbol}</span>
                        <span> {statValue}</span>
                      </React.Fragment>
                    ) : (
                      `${statValue} ${storeCurrency}`
                    )}
                  </span>
                </Tooltip>
              ) : type == 'percentage' ? (
                `${statValue} %`
              ) : (
                statValue
              )}
            </span>
            {total ? (
              <Tooltip
                content={
                  <span>
                    {currencySymbol
                      ? `${currencySymbol} ${total}`
                      : `${total} ${storeCurrency}`}
                  </span>
                }
              >
                <span className="ml-2 text-xl notranslate">
                  /&nbsp;&nbsp;{numeral(total).format(numberFormat)}
                </span>
              </Tooltip>
            ) : null}
          </div>
          {typeof total === 'undefined' || total != 0 || value == 0 ? (
            <span
              className={classNames(
                'mt-2 text-xs text-gray-600',
                styles.description
              )}
            >
              {description}
            </span>
          ) : null}
        </div>
      </Box>
    </div>
  )
}

StatCard.defaultProps = {
  className: '',
}
