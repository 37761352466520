import api from 'api'

export function shopifyInitiate(data = {}) {
  return api.get(`shopify/auth/initiate/`, {
    params: data,
  })
}

export function shopifyCallback(data = {}) {
  return api.post(`shopify/auth/callback/`, data)
}

export function shopifyOnlineCallback(data = {}) {
  return api.post(`shopify/auth/online_callback/`, data)
}
