import React, { useState } from 'react'
import sub from 'date-fns/sub'
import format from 'date-fns/format'
import getSymbolFromCurrency from 'currency-symbol-map'

import WaitForData from 'components/WaitForData'
import DateRangePicker from 'components/DateRangePicker'
import StatCard from 'components/StatCard'
import { AreaChart } from 'components/Chart'
import { DATE_FORMAT } from 'constants/date'
import { useApi } from 'hooks/api'
import useAppState from 'hooks/appState'
import { getStoreCurrency } from 'utils/account'

export default function WidgetAnalytics({ account, widget, store }) {
  const { name: shop } = account.data
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const widgetStats = useApi(`widgets/${widget.id}/stats/`, {
    shop,
  })

  const { state: appState } = useAppState()
  const isNativeCurrencyMode = appState.currencyMode == 'native'
  const storeCurrency = getStoreCurrency(account.data)
  const currencySymbol = getSymbolFromCurrency(
    isNativeCurrencyMode ? storeCurrency : 'USD'
  )

  function onDateRangeChange(date) {
    if (!date) {
      setStartDate(null)
      setEndDate(null)
      widgetStats.get(
        {
          date__gte: format(new Date(0), DATE_FORMAT),
          date__lte: format(new Date(), DATE_FORMAT),
        },
        { ignoreCache: true }
      )

      return
    }

    const [startDate, endDate] = date
    if (!startDate || !endDate) {
      return
    }

    setStartDate(startDate)
    setEndDate(endDate)
    widgetStats.get(
      {
        date__gte: format(startDate, DATE_FORMAT),
        date__lte: format(endDate, DATE_FORMAT),
      },
      {
        ignoreCache: true,
      }
    )
  }

  return (
    <WaitForData
      objects={[widgetStats]}
      loadingMessage="Loading widget analytics..."
      errorMessage="Trouble fetching analytics data..."
    >
      {() => {
        const { tilesData, conversionData } = widgetStats.data
        const charts = [
          {
            title: 'Widget Performance',
            areas: [
              { id: 'a', title: 'Page Views', key: 'ppv', color: '#9a3412' },
              {
                id: 'a',
                title: 'Widget Served',
                key: 'served',
                color: '#f97316',
              },
              {
                id: 'a',
                title: 'Widget Views',
                key: 'viewed',
                color: '#2dd4bf',
              },
              {
                id: 'a',
                title: 'Widget Clicks',
                key: 'clicked',
                color: '#ecc94b',
              },
            ],
          },
        ]
        return (
          <React.Fragment>
            <div className="flex flex-row items-center justify-end px-3 mb-3">
              <span className="mr-3 font-semibold">Filter by date range:</span>
              <DateRangePicker
                startDate={startDate}
                endDate={endDate}
                onRangeChange={onDateRangeChange}
              />
            </div>
            <div className="flex flex-row flex-wrap">
              {tilesData.map(
                ({
                  title,
                  currency,
                  total,
                  value,
                  description,
                  type,
                  nativeTotal,
                  nativeValue,
                }) => {
                  return (
                    <StatCard
                      title={title}
                      description={description}
                      numberFormat={'0a'}
                      currencySymbol={currencySymbol}
                      storeCurrency={storeCurrency}
                      className="w-full px-3 mb-6 lg:w-1/3"
                      value={
                        type == 'currency' && isNativeCurrencyMode
                          ? nativeValue
                          : value
                      }
                      total={
                        isNativeCurrencyMode && type == 'currency'
                          ? nativeTotal
                          : total
                      }
                      key={title}
                      type={type}
                    />
                  )
                }
              )}
            </div>
            {charts.map((chart) => {
              let tag = <div></div>
              if (chart.areas) {
                tag = (
                  <AreaChart
                    key={chart.title}
                    data={conversionData}
                    chart={chart}
                  />
                )
              }
              return (
                <div key={chart.title} className="w-full px-3 mb-6">
                  {tag}
                </div>
              )
            })}
          </React.Fragment>
        )
      }}
    </WaitForData>
  )
}
