import React, { useEffect, useState } from 'react'
import sub from 'date-fns/sub'
import format from 'date-fns/format'
import differenceInDays from 'date-fns/differenceInDays'

import { useToast } from 'components/Toast'
import useAppState from 'hooks/appState'
import { useApi } from 'hooks/api'
import { DATE_FORMAT } from 'constants/date'
import { parseStateDate } from 'utils/date'
import { pageLabelFromType } from 'utils/constants'
import { _find } from 'utils/lodash'

export default function useAnalytics({ account }) {
  const { state: appState, updateState: updateAppState } = useAppState()
  const startDate = parseStateDate(
    appState.statsStartDate,
    sub(new Date(), { days: 30 })
  )
  const endDate = parseStateDate(appState.statsEndDate, new Date())

  const [state, setState] = useState({
    startDate,
    endDate,
    currency: appState.currencyMode ? appState.currencyMode : 'usd',
    selectedWidgetIds: [],
    selectedPages: [],
    isFirstRun: true,
    params: {
      shop: account.data.name,
      ...(startDate ? { date__gte: format(startDate, DATE_FORMAT) } : {}),
      ...(endDate ? { date__lte: format(endDate, DATE_FORMAT) } : {}),
    },
  })
  const openToast = useToast()

  useEffect(() => {
    if (!state.isFirstRun) {
      stats.get(state.params)
      charts.get(state.params)
    } else {
      setState({ ...state, isFirstRun: false })
    }
  }, [state.params])

  const stats = useApi('analytics/stats/', state.params)
  const charts = useApi('analytics/charts/', state.params)
  const pages = useApi('pages/', { shop: account.data.name })
  const allPages = [
    ...(pages.data || []).map((page) => ({
      label: pageLabelFromType(page.type),
      value: page.type,
      widgets: page.widgets,
    })),
  ]
  const allWidgets = (pages.data || []).reduce(
    (widgets, page) => [...widgets, ...page.widgets],
    []
  )
  function onDateRangeChange(date) {
    if (!date) {
      return
    }

    const [startDate, endDate] = date
    if (!startDate || !endDate) {
      return
    }

    if (state.currency === 'native' && startDate < new Date(2022, 0, 1)) {
      openToast({
        type: 'error',
        text: 'Native Currency not available before JAN 1 2022',
      })
      return
    }

    if (differenceInDays(endDate, startDate) > 30) {
      openToast({
        type: 'error',
        text: 'Cannot select range more than 30 days',
      })
      return
    }

    updateAppState({ statsStartDate: date[0], statsEndDate: date[1] })
    setState({
      ...state,
      startDate,
      endDate,
      params: {
        ...state.params,
        date__gte: format(startDate, DATE_FORMAT),
        date__lte: format(endDate, DATE_FORMAT),
      },
    })
  }

  function onCurrencyChange(event) {
    const currency = event.target.value

    if (currency === 'native' && state.startDate < new Date(2022, 0, 1)) {
      openToast({
        type: 'error',
        text: 'Native Currency not available before JAN 1 2022',
      })
      return
    }
    setState({
      ...state,
      currency,
    })
    updateAppState({ currencyMode: currency })
  }

  function updateArrayFilter(stateKey, value) {
    let selectedPages = [],
      selectedWidgetIds = []
    if (stateKey == 'selectedPages') {
      selectedPages = value
      selectedPages.forEach((selectedPage) => {
        const page = _find(allPages, (p) => p.value == selectedPage)
        page.widgets.forEach((widget) => {
          selectedWidgetIds.push(widget.id)
        })
      })
    } else {
      selectedWidgetIds = value
      allPages.forEach((page) => {
        page.widgets.forEach((widget) => {
          if (selectedWidgetIds.includes(widget.id)) {
            if (!selectedPages.includes(page.value)) {
              selectedPages.push(page.value)
            }
          }
        })
      })
    }
    setState({
      ...state,
      selectedWidgetIds,
      selectedPages,
      params: {
        ...state.params,
        pages: selectedPages.length > 0 ? selectedPages.join(',') : undefined,
        widgets:
          selectedWidgetIds.length > 0
            ? selectedWidgetIds.join(',')
            : undefined,
      },
    })
  }

  return {
    stats,
    charts,
    state,
    allPages,
    allWidgets,
    onDateRangeChange,
    setSelectedWidgetIds: (widgetIds) =>
      updateArrayFilter('selectedWidgetIds', widgetIds),
    setSelectedPages: (pageTypes) =>
      updateArrayFilter('selectedPages', pageTypes),
    onCurrencyChange,
  }
}
